@import url(https://fonts.googleapis.com/css?family=Arvo:700);
@import url(https://fonts.googleapis.com/css?family=Seaweed+Script);
@import url(https://fonts.googleapis.com/css?family=Reenie+Beanie:regular);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  font-family: 'Roboto', sans-serif;
}

.titleText {
  font-family: 'Reem Kufi', sans-serif;
  font-weight: 600;
  color: black;
}

.buttonText {
  font-family: Arvo;
  text-transform: uppercase;
  margin-left: 5px;
  font-weight: 600;
  font-size: 12px;
}

.icons-social, a {
  padding: 10px;
  font-size: 23px;
  -webkit-transition: text-shadow 0.2s linear;
  transition: text-shadow 0.2s linear;
  text-shadow: none;
}

.big-quote {
  color: #4d9cd7 !important;
  font-weight: 800;
  font-family: "Reenie Beanie", arial, sans-serif;
}

a:hover {
  text-shadow: 1px 0px 20px #fff;
}

.tech-skills, div {
  margin: 5px;
  font-size: 30px;
}

.title-card {
  min-height: 120px;
}
body {
  background-color: #222;
}

.plate {
  text-align: center;
  width: 410px;
  margin-left: auto;
  margin-right: auto;
}

.arvo-font {
  font-family: Arvo;
}

.shadow {
  color: #fff;
  font-family: "Reenie Beanie", arial, sans-serif;
  font-weight: bold;
  text-shadow: -3px -3px 0 #4cbefc, 3px -3px 0 #4cbefc, -3px 3px 0 #4cbefc, 3px 3px 0 #4cbefc, 4px 4px 0 #36acfc, 5px 5px 0 #36acfc, 6px 6px 0 #36acfc, 7px 7px 0 #36acfc;
  line-height: 0.8em;
  letter-spacing: 0.1em;
  transform: scaleY(0.7);
  -webkit-transform: scaleY(0.7);
  -moz-transform: scaleY(0.7);
  margin: 0;
  text-align: center;
  letter-spacing: inherit;
}

.script {
  font-family: "Reenie Beanie", arial, sans-serif;
  color: #fff;
  text-align: center;
  font-size: 40px;
  position: relative;
  margin: 0;
}

.script span {
  z-index: -200;
  border-radius: 30px;
  padding: 0 0.3em;
}

.script:before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  top: 50%;
  width: 30%;
  border-bottom: 3px solid #fff;
}

.script:after {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  top: 50%;
  width: 30%;
  right: 0;
  border-bottom: 3px solid #fff;
}

.bottom-script {
  font-family: "Reenie Beanie", arial, sans-serif;
  color: #fff;
  text-align: center;
  font-size: 45px;
  position: relative;
  margin: 0;
}

.bottom-script span {
  padding: 0 0.3em;
}

.bottom-script:before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  top: 50%;
  width: 15%;
  border-bottom: 3px solid #fff;
}

.bottom-script:after {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  top: 50%;
  width: 15%;
  right: 0;
  border-bottom: 3px solid #fff;
}

.text1 {
  margin-top: -10px;
  font-size: 225px;
  text-align: center;
}

.text2 {
  font-size: 169px;
  text-align: center;
}

.text3 {
  margin-top: -40px;
  font-size: 95px;
  text-align: center;
}

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.beanie-font {
  font-family: "Reenie Beanie", arial, sans-serif;
}

.card-recommendation {
  font-family: "Reenie Beanie", arial, sans-serif;
  max-width: 500px;
  min-width: 300px;
}

@-webkit-keyframes pulse {
  0% {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
  }
  50% {
    opacity: .8;
    -webkit-transform: scale(.8);
            transform: scale(.8);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
  }
}

@keyframes pulse {
  0% {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
  }
  50% {
    opacity: .8;
    -webkit-transform: scale(.8);
            transform: scale(.8);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
  }
}

@-webkit-keyframes fade-slide-up {
  0% {
    opacity: 0;
    -webkit-transform: translateY(4rem);
            transform: translateY(4rem);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
  }
}

@keyframes fade-slide-up {
  0% {
    opacity: 0;
    -webkit-transform: translateY(4rem);
            transform: translateY(4rem);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
  }
}

.chevron {
  -webkit-animation: fade-slide-up 1s 1s ease-out forwards, pulse 2s 3s ease-out infinite;
          animation: fade-slide-up 1s 1s ease-out forwards, pulse 2s 3s ease-out infinite;
  opacity: 0;
}

.MuiGrid-item:nth-child(even) .card-recommendation {
  -o-transform: rotate(4deg);
  -webkit-transform: rotate(4deg);
  -moz-transform: rotate(4deg);
  position: relative;
  top: 5px;
  background: #cfc;
  border-bottom-left-radius: 80px 10px;
}

.MuiGrid-item:nth-child(odd) .card-recommendation {
  -o-transform: rotate(-2deg);
  -webkit-transform: rotate(-2deg);
  -moz-transform: rotate(-2deg);
  position: relative;
  top: -5px;
  background: #ccf;
}

.MuiGrid-item:first-child .card-recommendation {
  border-bottom-right-radius: 800px 12px;
  border-bottom-left-radius: 60px 8px;
}

.MuiGrid-item:nth-child(2n) .card-recommendation {
  border-top-right-radius: 60px 8px;
}

.MuiGrid-item:nth-child(3n) .card-recommendation {
  border-bottom-right-radius: 60px 8px;
  border-top-right-radius: 60px 8px;
  border-top-left-radius: 60px 3px;
  border-bottom-left-radius: 60px 3px;
}

.MuiGrid-item:nth-child(4n) .card-recommendation {
  border-bottom-left-radius: 60px 8px;
}

.MuiGrid-item:nth-child(5n) .card-recommendation {
  border-bottom-right-radius: 80px 6px;
  border-bottom-left-radius: 60px 8px;
  border-top-right-radius: 140px 12px;
  border-top-left-radius: 60px 8px;
}

.MuiGrid-item:nth-child(6n) .card-recommendation {
  border-bottom-left-radius: 120px 20px;
}

.MuiGrid-item:nth-child(7n) .card-recommendation {
  border-bottom-right-radius: 60px 20px;
  border-bottom-left-radius: 120px 8px;
  border-top-left-radius: 120px 8px;
}

.MuiGrid-item:nth-child(8n) .card-recommendation {
  border-bottom-right-radius: 60px 20px;
  border-bottom-left-radius: 120px 8px;
}

.MuiGrid-item:nth-child(4n) .card-recommendation {
  -o-transform: rotate(1.5deg);
  -webkit-transform: rotate(1.5deg);
  -moz-transform: rotate(1.5deg);
  position: relative;
  top: -5px;
  background: #ffc;
}
